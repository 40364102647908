<template>
  <div>
    <el-dialog
      :title="isEdit?'变更项目':'新增项目'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目编号" prop="billCode">
              <el-input v-model="form.billCode" placeholder="项目编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="立项日期" prop="billDate">
              <el-date-picker
                style="width:150px"
                @change="getBillCode(1)"
                v-model="form.billDate"
                placeholder="立项日期"
                :clearable="false"
                :editable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="isEdit">
            <el-form-item label="项目状态">
              <el-select v-model="form.billState" placeholder="项目状态" :disabled="form.billState==1">
                <el-option
                  v-for="item in billState"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目名称" prop="projectName">
              <el-input v-model="form.projectName" placeholder="项目名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始日期">
              <el-date-picker style="width:150px" v-model="form.startDate" placeholder="开始日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束日期">
              <el-date-picker style="width:150px" v-model="form.endDate" placeholder="结束日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="物资预算" prop="goodsBudget">
              <el-popover ref="goodsBudget" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.goodsBudget|money}}</span>
              </el-popover>
              <el-input
                v-popover:goodsBudget
                type="number"
                v-model="form.goodsBudget"
                placeholder="物资预算"
                @mousewheel.native.prevent
              >
                <i slot="prefix" class="iconfont iconmoney"></i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="费用预算" prop="expenseBudget">
              <el-popover ref="expenseBudget" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.expenseBudget|money}}</span>
              </el-popover>
              <el-input
                type="number"
                v-popover:expenseBudget
                v-model="form.expenseBudget"
                placeholder="费用预算"
                @mousewheel.native.prevent
              >
                <i slot="prefix" class="iconfont iconmoney"></i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="人工预算" prop="laborBudget">
              <el-popover ref="laborBudget" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.laborBudget|money}}</span>
              </el-popover>
              <el-input
                v-popover:laborBudget
                type="number"
                v-model="form.laborBudget"
                placeholder="人工预算"
                @mousewheel.native.prevent
              >
                <i slot="prefix" class="iconfont iconmoney"></i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预算控制">
              <el-switch v-model="form.budgetControl"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目地址">
              <el-input
                v-model="form.address"
                placeholder="点击地图选址"
                :readonly="true"
                @focus="showMap"
              >
                <el-button slot="append" icon="el-icon-search" @click="showMap"></el-button>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="操作员">
              <el-select v-model="form.assignOper" placeholder="指派操作员" clearable>
                <el-option
                  v-for="item in operators"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  :disabled="item.isStop"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单据附件">
              <BillAttach :attachId="form.attachId" ref="attach"></BillAttach>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="项目说明">
          <el-input v-model="form.remark" placeholder="项目说明" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
        <el-form-item label="变更说明" v-if="isEdit" prop="changeRemark">
          <el-input
            v-model="form.changeRemark"
            placeholder="变更说明"
            type="textarea"
            :rows="2"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button type="success" @click="save(true)" :loading="loading" v-if="!isEdit">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <show-map :item="mapItem" @success="getMapData"></show-map>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import billState from "@/views/project/BillState.js";
import ShowMap from "./Map";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  props: ["item"],
  mixins: [billcode],
  components: {
    ShowMap,
    BillAttach,
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        billCode: [this.$g.required],
        projectName: [this.$g.required],
        billDate: [this.$g.required],
        goodsBudget: [this.$g.required, this.$g.positive],
        expenseBudget: [this.$g.required, this.$g.positive],
        laborBudget: [this.$g.required, this.$g.positive],
        changeRemark: [this.$g.required],
      },
      billState: billState,
      mapItem: {},
    };
  },
  computed: {
    isEdit() {
      return !(this.item.id == undefined);
    },
    operators() {
      let res = [];

      this.$store.state.baseinfo.Operator.forEach((item) => {
        if (!item.isAdmin) {
          res.push({ ...item });
        }
      });
      return res;
    },
  },
  watch: {
    item: {
      handler: function (value) {
        if (value.id) {
          this.loading = true;
          this.$get("Project/Detail", {
            id: value.id,
          })
            .then((r) => {
              this.form = r;
              if (this.$refs["form"]) {
                this.$refs["form"].resetFields();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.reset();
        }
        this.visible = true;
      },
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = {
        billCode: "",
        billDate: new Date(),
        goodsBudget: null,
        expenseBudget: null,
        laborBudget: null,
        address: "",
        province: "",
        city: "",
        lat: null,
        lng: null,
        budgetControl: false,
        assignOper: null,
        attachId: guid(),
      };
      this.getBillCode(1);
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    showMap() {
      this.mapItem = {
        province: this.form.province,
        city: this.form.city,
        lat: this.form.lat,
        lng: this.form.lng,
      };
    },
    getMapData(res) {
      this.form.address = res.address;
      this.form.lng = res.lng;
      this.form.lat = res.lat;
      this.form.province = res.province;
      this.form.city = res.city;
    },
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }

        let values = this.form;
        this.loading = true;
        if (values.id) {
          this.$post("Project/EditProject", values)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$store.commit("updateBaseInfo", {
                info: "Project",
                item: r,
              });
              this.visible = false;
              this.$emit("success");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Project/AddProject", values)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$store.commit("addBaseInfo", {
                info: "Project",
                item: r,
              });
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style>
</style>