<template>
  <div>
    <el-dialog
      title="地图选址"
      :visible.sync="visible"
      :fullscreen="true"
      :close-on-click-modal="false"
      @close="close"
    >
      <div style="position: absolute;z-index: 100;left: 10px;top: 45px;">
        <el-form label-width="40px">
          <el-form-item label="过滤">
            <area-cascader v-model="area" @city-change="cityChange"></area-cascader>
            <el-autocomplete
              class="inline-input"
              v-model="search"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              :trigger-on-focus="false"
              @select="searchSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="地址">
            <el-input :value="address" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="()=>{this.visible=false}">选定关闭</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div id="mapContainer"></div>
    </el-dialog>
  </div>
</template>

<script>
import AreaCascader from "@/components/AreaCascader/AreaCascader";
import { mapState } from "vuex";
export default {
  components: {
    AreaCascader
  },
  props: ["item"],
  data() {
    return {
      visible: false,
      search: "",
      map: null,
      area: [], //省,市
      lat: null,
      lng: null,
      address: ""
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  watch: {
    item: {
      handler: function(value) {
        this.visible = true;
        this.search = "";
        this.address = "";
        this.area = [
          value.province || this.userInfo.province,
          value.city || this.userInfo.city
        ];
        this.lat = value.lat;
        this.lng = value.lng;

        this.$nextTick(() => {
          this.initMap();
        });
      },
      deep: true
    }
  },
  methods: {
    initMap() {
      if (!this.map) {
        this.map = new AMap.Map("mapContainer");
      }
      this.map.clearMap();
      if (this.lat && this.lng) {
        let location = new AMap.LngLat(this.lng, this.lat);
        this.setMarkerAndData(location);
      } else {
        this.map.setCity(this.area[1]);
      }
    },
    cityChange(city) {
      this.map.clearMap();
      this.search = "";
      this.address = "";
      this.lat = null;
      this.lng = null;
      this.map.setCity(city);
    },
    querySearch(queryString, cb) {
      let array = [];
      let city = this.area[1];

      AMap.plugin("AMap.AutoComplete", () => {
        let autoComplete = new AMap.AutoComplete({
          city: city,
          datatype: "poi",
          citylimit: true
        });
        autoComplete.search(queryString, (status, result) => {
          if (status == "no_data") {
            this.$message("没有搜索到内容");
          }
          if (result && result.tips && result.tips.length > 0) {
            result.tips.forEach(item => {
              if (item.name && item.location) {
                array.push({
                  value: item.name,
                  location: item.location
                });
              }
            });
          }
          cb(array);
        });
      });
    },
    searchSelect(item) {
      this.setMarkerAndData(item.location);
    },
    setMarkerAndData(location) {
      this.map.clearMap();

      let marker = new AMap.Marker({
        position: location,
        draggable: true
      });

      marker.on("dragend", e => {
        this.search = "";
        this.setData(e.lnglat);
      });
      this.map.add(marker);
      this.map.setFitView();

      this.setData(location);
    },
    setData(location) {
      this.lat = location.getLat();
      this.lng = location.getLng();
      let geocoder;
      //加载地理编码插件
      AMap.plugin(["AMap.Geocoder"], () => {
        //加载地理编码插件
        geocoder = new AMap.Geocoder({
          radius: 1000, //以已知坐标为中心点，radius为半径，返回范围内兴趣点和道路信息
          extensions: "base" //返回地址描述以及附近兴趣点和道路信息，默认“base”
        });
        //返回地理编码结果
        geocoder.on("complete", result => {
          if (result.info == "OK" && result.regeocode) {
            let res = result.regeocode;
            let province = "";
            let city = "";
            if (res.addressComponent) {
              province = res.addressComponent.province || "";
              city = res.addressComponent.city || "";
            }
            this.address = (res.formattedAddress || "")
              .replace(province, "")
              .replace(city, "");
          }
        });
        //逆地理编码
        geocoder.getAddress(location);
      });
    },
    close() {
      this.$emit("success", {
        address: this.address,
        province: this.area[0],
        city: this.area[1],
        lat: this.lat,
        lng: this.lng
      });
    }
  }
};
</script>

<style scoped>
* >>> .el-dialog__body {
  padding: 0px;
}
.el-form {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
}
#mapContainer {
  height: calc(100vh - 35px);
}
#mapContainer >>> .amap-logo img {
  display: none;
}
#mapContainer >>> .amap-copyright {
  color: transparent;
}
</style>